import React from "react"
import { graphql, Link } from 'gatsby'
import styles from "./events.module.css"

import SEO from "../components/seo"
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

const Mudd = ( {data}) => {
    const fullSize = data.images.edges.map(edge => edge.node.full.fluid.src)
    const thumbs = data.images.edges.map(edge => edge.node.thumb.fluid)
    return (
        <div className={"content"}>
            <SEO title="Impressionen Max Cuap Vol. I" />
            <h1>Impressionen Max Cuap Vol. I</h1>
            <p className={"long"}>
            </p>
            <Gallery images={fullSize} thumbs={thumbs} />
            <Link className={styles.backLink} to="events">zurück →</Link>

        </div>)
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "vol1" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          thumb: childImageSharp {
            fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
          }
          full: childImageSharp {
            fluid(
              maxWidth: 1024
              quality: 85
              srcSetBreakpoints: [576, 768, 992, 1200]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Mudd